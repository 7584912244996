import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Footer from '../components/Footer'
import Img from "gatsby-image"


const BlogPostTemplate = ({ data }) => {
  const postData = data.jsonJson
  let featuredImg = undefined


  if (postData.featured_media && postData.featured_media.localFile) {
    console.log(postData.featured_media.localFile)
    featuredImg = postData.featured_media.localFile.childImageSharp.fixed
  }


  // console.log(postData.featured_media)
  // if (postData.featured_media && postData.featured_media.localFile) {
  //   featuredImg = postData.featured_media.localFile.childImageSharp.fixed
  // }

  // let fiximage = ()=>
  // {
  //   let images = document.getElementsByTagName('img')
  //   console.log(images)

  //   for (var img of images) {
  //     console.log('farts 2')
  //     var newrl = img.src.split("wp-content")[1]
  //     img.src = "/wp-content"+newrl
  //     console.log(img.src)
  //   };
  //}

  return (
    <Layout>
      <header id="header">
        <Link to="/" >
          <div className="logo">
            {/* <span className="icon fa-skull-crossbones"></span> */}
          </div></Link>
      </header>
      <div id="wp-blog-wrapper">
        {featuredImg && <Img fixed={featuredImg} className="header-image" />}
        <h3 style={{ fontSize: 33, marginTop: 0 }} dangerouslySetInnerHTML={{ __html: postData.title }} />
        <h4 dangerouslySetInnerHTML={{ __html: postData.date }} />
        <div dangerouslySetInnerHTML={{ __html: postData.content }} className="wp-blog-body" />
      </div>
      <Link to={"/blog/1"}><p>back to blog</p></Link>
      <Footer />
    </Layout>
  )
}
export const query = graphql`
query($wordpress_id: Int!){
    jsonJson( wordpress_id: {eq: $wordpress_id}) {
      title
      slug
      wordpress_id
      date(formatString: "MMMM Do, YYYY")
      content
      categories
      featured_media {
        media_details {
          file
        }
      }
    }
  }
`

export default BlogPostTemplate
